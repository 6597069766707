'use strict';
app.factory('authInterceptorService', ['$q', '$rootScope', '$cookies', '$injector', function ($q, $rootScope, $cookies, $injector)
{
    //60 requests per minute
    let rate = 60;
    let rateLimiter = [];

    return {
        request: function (config)
        {
            if (typeof $rootScope.getAuthentication === "undefined")
            {
                //not ready yet, return original config
                return config;
            }

            let url = config.url.toLowerCase();
            let authentication = $rootScope.getAuthentication();

            if (authentication.token
                && typeof url == "string"
                && !url.endsWith(".html")
                && !url.endsWith(".js")
                && !url.endsWith(".css")
                && !url.endsWith(".confg")
                && !url.endsWith(".woff")
                && !url.endsWith(".woff2")
                && !url.endsWith(".json")
                && !url.endsWith(".resx")
                && url != "recursivereportmenutemplate")
            {
                //add custom header to authorized request
                config.headers = config.headers || {};
                config.headers.Authorization = 'Bearer ' + authentication.token;
                config.headers.LangKey = $rootScope.userSetting ? $rootScope.userSetting.langKey : 1;
                config.headers.ClientID = authentication.clientID;
                config.headers.ClientKey = authentication.ClientKey;
                config.headers.UserKey = authentication.userKey;
                config.headers.DeviceTZOffset = deviceTimezoneOffset();

                let now = new Date();
                let request = rateLimiter.find(x => x.url === config.url);

                if (typeof request === "undefined")
                {
                    request = { url: config.url, count: 1, last: now };
                    rateLimiter.push(request);
                }
                else
                {
                    if ((now.getTime() - request.last.getTime()) > 60000)
                    {
                        //reset rate limit
                        request.count = 1;
                        request.last = now;
                    }
                    else
                    {
                        //increase counter
                        request.count++;
                    }
                }

                if (request.count > rate)
                {
                    D(() => console.log("Rate Limiter (" + rate + " request/minute): " + config.url + " Count : " + request.count + " Delay : " + (request.count * 1000) + " ms"));

                    return $injector.get('$timeout')(function ()
                    {
                        return config;
                    }, (request.count * 1000));
                }
                else
                {
                    return config;
                }
            }
            else
            {
                return config;
            }
        },
        responseError: function (response)
        {
            //server return error, could be server error or server rejected the request due to unauthorized request(token expired), call relogin to check for token

            if (typeof response != "undefined" && response != null && typeof response.config != "undefined" && response.config != null)
            {
                //record retry count
                response.config.retryCount = (typeof response.config.retryCount == "undefined") ? 0 : response.config.retryCount;

                let allowRetry = (response.status === 0);
                let url = response.config.url.toLowerCase();

                //skip specific case
                if (url.contains("/signalr/") || url.contains("/reports/") || url.contains("import") || (response.config.retry ?? true) == false)
                {
                    //skip for these as it is handled separately
                    allowRetry = false;
                }
                else if (url.endsWith("/token") && (response?.data?.error ?? "").includes("invalid_grant"))
                {
                    //skip if invalid grant => wrong password etc
                    allowRetry = false;
                }
                else if (url.includes("authentication"))
                {
                    //dont retry this as it is time sensitive
                    allowRetry = false;
                }
                else if (url.contains("process"))
                {
                    //allow only if it is not timeout
                    allowRetry = !(((response?.data?.ExceptionType) ?? "").toLowerCase().includes("TimeoutException".toLowerCase()));
                }
                //else if (response.config.url.length > 2000)
                //{
                //    //skip retry if url way too long
                //    allowRetry = false;
                //    response.status = response.status === 0 ? 414 : response.status;
                //}

                if (allowRetry && !window.location.href.contains("localhost"))
                {
                    let module = Object.entries($rootScope.domainUrls).filter(item => item[0] != "Reconfigure" && item[0] != "DNSGroup").map(item => item[1]).find(item => item.findIndex(x => response.config.url.contains(x)) > -1);

                    //found module
                    if (typeof module != "undefined" && module.length > 0)
                    {
                        //get current and next domain url
                        let index = module.findIndex(x => response.config.url.contains(x));
                        let selectedIndex = ((index + 1) >= module.length) ? 0 : (index + 1);

                        //retry for fixed number of times
                        if (response.config.retryCount < 3)
                        {
                            //first retry    0 -  10 s
                            //second retry  10 -  60 s
                            //third retry   60 - 120 s
                            //forth retry  120 - 180 s
                            //fifth retry  180 - 240 s
                            let delay =
                                response.config.retryCount == 4 ? (Math.floor(Math.random() * (240 - 180 + 1)) + 180)
                                    : response.config.retryCount == 3 ? (Math.floor(Math.random() * (180 - 120 + 1)) + 120)
                                        : response.config.retryCount == 2 ? (Math.floor(Math.random() * (120 - 60 + 1)) + 60)
                                            : response.config.retryCount == 1 ? (Math.floor(Math.random() * (60 - 10 + 1)) + 10)
                                                : response.config.retryCount <= 0 ? (Math.floor(Math.random() * (10 - 0 + 1)) + 0)
                                                    : 0;

                            return $injector.get('$timeout')(function ()
                            {
                                //retry with alternative domain url
                                D(() => console.log("Redirect(Interceptor) : " + response.status + " " + response.config.method + " " + response.config.url + " To : " + response.config.url.replace(module[index], module[selectedIndex]) + " Attempt : " + (response.config.retryCount + 1) + " Delay : " + (delay * 1000)));

                                response.config.url = response.config.url.replace(module[index], module[selectedIndex]);
                                response.config.retryCount++;

                                return $injector.get('$http')(response.config);
                            }, delay * 1000);
                        }
                        else
                        {
                            D(() => console.log("Redirect(Interceptor) : " + response.status + " " + response.config.method + " " + response.config.url + " exceeded max retry limit."));
                        }
                    }
                }
            }

            $rootScope.unauthorized = true;
            $rootScope.$emit("CallReloginMethod", {});

            return $q.reject(response);
        },
        requestError: function (response)
        {
            //rejected by request interceptor, likely to be unauthorized request(token expired), call relogin to check for token
            $rootScope.unauthorized = true;
            $rootScope.$emit("CallReloginMethod", {});

            return $q.reject(response);
        },
    };
}]);